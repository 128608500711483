import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/user",
    name: "Member",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/Member.vue"),
    // meta: { requireAuth: true },
  },
  {
    path: "/order",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/Order.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/MemberOrder.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin.vue"),
    // meta: { requireAuth: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // 看看 to 和 from 兩個 arguments 會吐回什麼訊息
  console.log("to: ", to);
  console.log("from: ", from);

  // 目的路由在meta上是否有設置requireAuth: true
  if (to.meta.requireAuth) {
    // 獲取Cookies當中的login資訊並取得token
    const info = Cookies.get("login");
    if (info) {
      const token = JSON.parse(info).token;
      console.log(token);
      // 如果token不為空，且確實有這個欄位則讓路由變更
      if (token.length > 0 || token === undefined) {
        next();
      } else {
        // 未通過則導回login頁面
        next({ 'name': 'Home' });
      }
    } else {
      next({ 'name': 'Home' });
    }
  } else {
    next();
  }
});

export default router;
