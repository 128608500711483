import { defineStore } from "pinia";

export const useCartStore = defineStore({
    id: "cart",
    state: () => ({
        cartList: JSON.parse(localStorage.getItem("wp-cart")) || {},
        counter: JSON.parse(localStorage.getItem("wp-cart")) ? Object.values(JSON.parse(localStorage.getItem("wp-cart"))).reduce((accumulator, value) => {
            return accumulator + value;
        }, 0) : 0,
        orderSum: 0,
        orderSumWithComma: "",
    }),
    actions: {
        increaseCount(item) {
            if (item.p_id in this.cartList) {
                this.cartList[item.p_id] += 1;
            } else {
                this.cartList[item.p_id] = 1;
            }
            this.counter++;
            this.orderSum += item.p_price;
            this.orderSumWithComma = this.numbersWithCommas(this.orderSum);
            localStorage.setItem("wp-cart", JSON.stringify(this.cartList));
            console.log(JSON.parse(localStorage.getItem("wp-cart")));
        },
        decreaseCount(item) {
            if (item.p_id in this.cartList) {
                if (this.cartList[item.p_id] > 0) {
                    this.cartList[item.p_id] -= 1;
                }
                if (this.cartList[item.p_id] == 0) {
                    delete this.cartList[item.p_id];
                }
            }
            if (this.counter > 0) {
                this.counter--;
                this.orderSum -= item.p_price;
                this.orderSumWithComma = this.numbersWithCommas(this.orderSum);
            }
            localStorage.setItem("wp-cart", JSON.stringify(this.cartList));
            console.log(JSON.parse(localStorage.getItem("wp-cart")));

        },
        numbersWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
});
