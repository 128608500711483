<template>
  <div class="fixed-bottom-right">
    <div class="d-flex flex-column align-items-center">
      <a class="mb-2" href=""><img width="36" src="@/assets/media/wavepo_instagram.png" alt="" /></a>
      <a class="mb-2" href=""><img width="42" src="@/assets/media/wavepo_line.png" alt="" /></a>
      <a href=""><img width="36" src="@/assets/media/wavepo_facebook.png" alt="" /></a>
    </div>
  </div>
  <div class="sticky-top">
    <div class="bg-secondary py-1">
      <div class="container">
        <div class="d-flex justify-content-end align-items-center">
          <a class="me-2" href="#"><img width="16" src="@/assets/media/wavepo_facebook.png" alt="" /></a>
          <a class="me-2" href="#"><img width="18" src="@/assets/media/wavepo_line.png" alt="" /></a>
          <a href="#"><img width="16" src="@/assets/media/wavepo_instagram.png" alt="" /></a>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-0">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center">
            <router-link to="/"><img width="60" src="@/assets/wavepo-logo.png" alt="" /></router-link>
          </div>
          <div>
            <router-link class="navbar-brand" to="/"><img width="120" src="@/assets/wavepo-name.png" alt="" /></router-link>
          </div>
          <div class="d-flex">
            <button type="button" class="btn btn-dark position-relative me-2">
              <a href="/order" class="text-decoration-none text-white">查看訂單</a>
            </button>
            <button type="button" class="btn btn-dark position-relative me-2" data-bs-toggle="offcanvas" data-bs-target="#cart-offcanvas">
              購物車
              <span class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-danger">
                {{ storeCart.counter }}
                <span class="visually-hidden">咖啡購買品項</span>
              </span>
            </button>
            <!-- <button v-if="false" type="button" class="btn btn-dark position-relative" data-bs-toggle="modal" data-bs-target="#loginModal">登入</button> -->
            <!-- <button type="button" class="btn btn-dark position-relative" id="memberDropDownBtn" data-bs-toggle="dropdown" aria-expanded="false">沙拉</button>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="memberDropDownBtn">
              <li><router-link class="dropdown-item" to="/orders">歷史訂單</router-link></li>
              <li><a class="dropdown-item" href="#">登出</a></li>
            </ul> -->
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#wavepo-navbar" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button> -->
            <!-- <div class="collapse navbar-collapse justify-content-end" id="wavepo-navbar">
              <ul class="navbar-nav text-center">
                <li class="nav-item" data-bs-toggle="modal" data-bs-target="#registerModal">
                  <a class="nav-link" aria-current="page" href="#">註冊</a>
                </li>
                <li class="navbar-link-separator nav-item"></li>
                <li class="nav-item" data-bs-toggle="modal" data-bs-target="#loginModal">
                  <a class="nav-link" aria-current="page" href="#">登入</a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
  <router-view style="min-height: 100vh"></router-view>
  <section>
    <footer class="footer p-3">
      <div class="container text-center">
        <div class="d-flex justify-content-center">
          <div class="row justify-content-end">
            <div class="col-10">
              <div class="row text-start fw-bolder">
                <div class="col-md-6 mb-2">IG 搜尋：WAVEPO_ROASTER</div>
                <div class="col-md-6 mb-2">Line 搜尋：id</div>
                <div class="col-md-6 mb-2">FB 搜尋：浪勁烘⾖⼯作室</div>
                <div class="col-md-6 mb-2">聯絡電話：09</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row text-xs align-items-center fw-bolder">
          <div class="col-6 text-start">©2022 浪勁烘⾖⼯作室 All Rights Reserved</div>
          <div class="col-6 text-end">
            <div class="row">
              <div class="col-12">有任何問題，私訊皆有專⼈服務</div>
              <div class="col-12">上班時間：9:00-12:00．13:30-18:00</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
  <login-modal></login-modal>
  <cart-modal></cart-modal>
  <div class="offcanvas offcanvas-start" tabindex="-1" data-bs-scroll="true" id="cart-offcanvas" aria-labelledby="cart-offcanvasLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="cart-offcanvasLabel">購物車</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div v-if="Object.keys(storeCart.cartList).length == 0" class="d-flex flex-column justify-content-start h-100">趕快把商品放入購物車吧</div>
      <div v-else class="d-flex flex-column justify-content-between h-100">
        <div class="bd-highlight">
          <div v-for="(item, itemKey) in storeCart.cartList" :key="itemKey">
            <div class="d-flex px-3">
              <div class="img-fluid me-3"><img :src="require(`@/assets/product/${getProduct(itemKey).p_src}`)" :alt="getProduct(itemKey).p_ch_name" width="120" /></div>
              <div class="d-flex flex-column">
                <p>{{ getProduct(itemKey).p_ch_name }}</p>
                <p>{{ item }} x {{ getProduct(itemKey).p_currency }}{{ getProduct(itemKey).p_price }}（{{ getProduct(itemKey).p_spec }}）</p>
              </div>
            </div>
            <div class="d-flex">
              <button type="button" class="btn btn-success w-75 me-2" @click="storeCart.increaseCount(getProduct(itemKey))">新增</button>
              <button type="button" class="btn btn-outline-danger w-25" @click="storeCart.decreaseCount(getProduct(itemKey))">減量</button>
            </div>
            <hr />
          </div>
        </div>
        <div class="p-2">
          <button type="button" class="btn btn-dark position-relative me-2 w-100" data-bs-toggle="modal" data-bs-target="#cartModal" @mouseup="closeOffcanvas">訂單結帳</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useCartStore } from "@/store";
import LoginModal from "./components/LoginModal";
import CartModal from "./components/CartModal";
import { greeting } from "@/api.js";
import { getProduct } from "@/global";

greeting().then((res) => {
  console.log(res.data);
  console.log("from api");
});
const storeCart = useCartStore();
</script>
<style>
#app {
  background-image: url("~@/assets/background.png");
}
.navbar-link-separator {
  border-right: 1px solid hsla(0, 0%, 100%, 0.4);
  /* border-right: 1px solid hsla(0, 47%, 18%, 0.4); */
}
/* .carousel-height {
  width: 100%;
  height: auto;
} */
.bg-cover {
  background-position: center;
  background-size: cover;
}
.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
}
.buy-height {
  height: 70vh;
}

.bg-colombia {
  background-image: linear-gradient(rgba(62, 67, 123, 0.3), rgba(62, 67, 123, 0.1)), url("~@/assets/background.png");
}
.bg-ethiopia {
  background-image: linear-gradient(rgba(123, 62, 62, 0.3), rgba(123, 62, 62, 0.1)), url("~@/assets/background.png");
}
.bg-costarica {
  background-image: linear-gradient(rgba(92, 123, 62, 0.3), rgba(92, 123, 62, 0.1)), url("~@/assets/background.png");
}
.bg-coffeeworld {
  background-image: linear-gradient(rgba(123, 88, 62, 0.3), rgba(123, 88, 62, 0.1)), url("~@/assets/background.png");
}
@media only screen and (max-width: 768px) {
  .text-md-white {
    color: #fff;
  }

  .order {
    z-index: -1;
  }

  .bg-md-light {
    background: none;
  }

  .bg-md-opacity {
    background: rgba(0, 0, 0, 0.3);
  }

  .buy-height {
    height: 850px;
  }
}
.fixed-bottom-right {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}
.bg-header-banner {
  background-image: url("~@/assets/banner-1.jpg");
  background-repeat: no-repeat;
}

.bg-footer-banner {
  background-image: url("~@/assets/banner-2.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
.bean-cat {
  cursor: pointer;
}

.bean-cat:hover {
  opacity: 0.8;
}

.bean-pill {
  background-color: rgb(85, 84, 84);
}
.accordion-button {
  height: 80px;
}
.colombia-map-cat {
  background-image: url("~@/assets/map-colombia.png");
  background-repeat: no-repeat;
  height: 100px;
}
.ethiopia-map-cat {
  background-image: url("~@/assets/map-ethiopia.png");
  background-repeat: no-repeat;
  height: 100px;
}
.costarica-map-cat {
  background-image: url("~@/assets/map-costarica.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  height: 100px;
}
.coffeeworld-map-cat {
  background-image: url("~@/assets/map-coffeeworld.png");
  background-repeat: no-repeat;
  height: 100px;
}

.footer {
  background-image: url("~@/assets/bg-footer.png");
  width: 100%;
}
.text-xs {
  font-size: 0.75rem;
}
.h-55 {
  height: 55vh;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
</style>
