const beans = [
    {
        type: "colombia",
        name: "哥倫比亞",
        bean_type: "bean-colombia",
        ulr: "~@/assets/map-colombia.png",
        bg_class: "bg-colombia",
        spy: "colombia-map-spy",
        cat: "colombia-map-cat",
        accordion_id: "colombia-map",
        collapse_id: "colombia-map-collapse",
    },
    {
        type: "ethiopia",
        name: "衣索比亞",
        bean_type: "bean-ethiopia",
        ulr: "~@/assets/map-ethiopia.png",
        bg_class: "bg-ethiopia",
        spy: "ethiopia-map-spy",
        cat: "ethiopia-map-cat",
        accordion_id: "ethiopia-map",
        collapse_id: "ethiopia-map-collapse",
    },
    {
        type: "costarica",
        name: "哥斯大黎加",
        bean_type: "bean-costarica",
        ulr: "~@/assets/map-costarica.png",
        bg_class: "bg-costarica",
        spy: "costarica-map-spy",
        cat: "costarica-map-cat",
        accordion_id: "costarica-map",
        collapse_id: "costarica-map-collapse",
    },
    {
        type: "coffeeworld",
        name: "限量特殊豆",
        bean_type: "bean-coffeeworld",
        ulr: "~@/assets/map-coffeeworld.png",
        bg_class: "bg-coffeeworld",
        spy: "coffeeworld-map-spy",
        cat: "coffeeworld-map-cat",
        accordion_id: "coffeeworld-map",
        collapse_id: "coffeeworld-map-collapse",
    },
]

const productList = {
    colombia: [
        {
            p_id: "14",
            p_ch_name: "哥倫比亞 小飛象 水洗",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 450,
            p_spec: "半磅",
            p_src: "colombia/14.jpg",
            p_stock: 10,
            p_category: "colombia",
        },
        {
            p_id: "15",
            p_ch_name: "哥倫比亞 天堂莊園 玫瑰茶",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 900,
            p_spec: "半磅",
            p_src: "colombia/15.jpg",
            p_stock: 10,
            p_category: "colombia",
        },
        {
            p_id: "16",
            p_ch_name: "哥倫比亞 天堂莊園 青檸香頌",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 900,
            p_spec: "半磅",
            p_src: "colombia/16.jpg",
            p_stock: 10,
            p_category: "colombia",
        },
        {
            p_id: "17",
            p_ch_name: "哥倫比亞 天堂莊園 豔夏花荔",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 920,
            p_spec: "半磅",
            p_src: "colombia/17.jpg",
            p_stock: 10,
            p_category: "colombia",
        },
    ],
    costarica: [
        {
            p_id: "10",
            p_ch_name: "哥斯大黎加 艾瑞斯蜜神",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 450,
            p_spec: "半磅",
            p_src: "costarica/10.jpg",
            p_stock: 10,
            p_category: "costarica",
        },
        {
            p_id: "11",
            p_ch_name: "哥斯大黎加 芒果莊園",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 680,
            p_spec: "半磅",
            p_src: "costarica/11.jpg",
            p_stock: 10,
            p_category: "costarica",
        },
        {
            p_id: "12",
            p_ch_name: "哥斯大黎加 美景莊園",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 700,
            p_spec: "半磅",
            p_src: "costarica/12.jpg",
            p_stock: 10,
            p_category: "costarica",
        },
        {
            p_id: "13",
            p_ch_name: "哥斯大黎加 藍火山",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 580,
            p_spec: "半磅",
            p_src: "costarica/13.jpg",
            p_stock: 10,
            p_category: "costarica",
        },
    ],
    ethiopia: [
        {
            p_id: "4",
            p_ch_name: "衣索比亞 古吉 甜甜果",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 430,
            p_spec: "半磅",
            p_src: "ethiopia/4.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
        {
            p_id: "5",
            p_ch_name: "衣索比亞 西達摩 G4 TOP",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 430,
            p_spec: "半磅",
            p_src: "ethiopia/5.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
        {
            p_id: "6",
            p_ch_name: "衣索比亞 西達摩 桃可可",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 450,
            p_spec: "半磅",
            p_src: "ethiopia/6.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
        {
            p_id: "7",
            p_ch_name: "衣索比亞 科契爾 寶姬",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 400,
            p_spec: "半磅",
            p_src: "ethiopia/7.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
        {
            p_id: "8",
            p_ch_name: "衣索比亞 耶加雪菲 艾瑞嘉",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 400,
            p_spec: "半磅",
            p_src: "ethiopia/8.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
        {
            p_id: "9",
            p_ch_name: "衣索比亞 蓋德奧 耶加雪菲鎮 泰瑞莎村",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 430,
            p_spec: "半磅",
            p_src: "ethiopia/9.jpg",
            p_stock: 10,
            p_category: "ethiopia",
        },
    ],
    coffeeworld: [
        {
            p_id: "1",
            p_ch_name: "巴拿馬 巨石莊園 翡翠藍標藝妓",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 700,
            p_spec: "100g",
            p_src: "coffeeworld/1.jpg",
            p_stock: 10,
            p_category: "coffeeworld",
        },
        {
            p_id: "2",
            p_ch_name: "瓜地馬拉 花神丸豆",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 999,
            p_spec: "半磅",
            p_src: "coffeeworld/2.jpg",
            p_stock: 0,
            p_category: "coffeeworld",
        },
        {
            p_id: "3",
            p_ch_name: "緬甸 紅日高山",
            p_en_name: "",
            p_currency: "NTD",
            p_price: 999,
            p_spec: "半磅",
            p_src: "coffeeworld/3.jpg",
            p_stock: 5,
            p_category: "coffeeworld",
        },
    ],
};

let productMap = new Map();
for (const [key, value] of Object.entries(productList)) {
    for (const [key2, value2] of Object.entries(value)) {
        productMap.set(value2.p_id, value2)
    }
}

export function getAllProduct() {
    return productList;
}

export function getProduct(prorudct_id) {
    return productMap.get(prorudct_id);
}

export function getAllType() {
    return beans;
}


