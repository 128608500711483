<template>
  <div class="modal fade" id="loginModal" aria-hidden="true" aria-labelledby="loginModalLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-dark">
          <h5 class="text-white modal-title" id="exampleModalToggleLabel">登入</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form action="">
            <img class="w-50 mx-auto d-block" src="~@/assets/icon/btn_login_base.png" alt="" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
