<template>
  <div class="modal" id="cartModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="cartModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cartModalLabel">浪勁烘⾖⼯作室 訂單結帳</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <h2 class="text-center rounded-pill py-2 bg-light">購買資訊</h2>
            <table class="table">
              <tbody>
                <tr>
                  <th scope="col"></th>
                  <th class="col">商品名稱</th>
                  <th scope="col">規格</th>
                  <th scope="col">數量</th>
                  <th scope="col" class="text-end">單價</th>
                </tr>
              </tbody>
              <tfoot>
                <tr v-for="(item, itemKey) in storeCart.cartList" :key="itemKey">
                  <th scope="row"><img :src="require(`@/assets/product/${getProduct(itemKey).p_src}`)" :alt="getProduct(itemKey).p_ch_name" style="width: 20vw" /></th>
                  <td>{{ getProduct(itemKey).p_ch_name }}</td>
                  <td>{{ getProduct(itemKey).p_spec }}</td>
                  <td>{{ item }}</td>
                  <td class="text-end">${{ getProduct(itemKey).p_price }}</td>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <td></td>
                  <td>運費</td>
                  <td class="text-end"><strong>$60</strong></td>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <td></td>
                  <td>輸入折扣碼</td>
                  <td class="text-end">
                    <strong>
                      <div class="input-group input-group-sm">
                        <input type="text" class="form-control" aria-label="wavepo coffee coupon" aria-describedby="wavepo-coffee-coupon" /></div
                    ></strong>
                  </td>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <td></td>
                  <td>折扣</td>
                  <td class="text-end"><strong>-$60</strong></td>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <td></td>
                  <td colspan="2"><small class="text-secondary">× 新會員運費折扣（不限金額免運）</small></td>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <td></td>
                  <td>總計</td>
                  <td class="text-end">
                    <strong>${{ storeCart.orderSumWithComma }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="container-fluid">
            <form class="needs-validation" novalidate>
              <h2 class="text-center rounded-pill py-2 bg-light">訂購人資訊</h2>
              <div class="row my-4">
                <div class="col">
                  <input type="text" class="form-control" placeholder="姓名" aria-label="contact name" v-model="current_order.receiver_name" />
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="連絡電話" aria-label="contact phone number" v-model="current_order.receiver_phone" />
                </div>
              </div>
              <div class="row my-4 align-items-center">
                <div class="col">
                  <p class="text-white">來點廣告詞</p>
                  <select class="form-select" aria-label="shipment shop number" v-model="current_order.ship_store">
                    <option value="" selected>寄送方式</option>
                    <option value="shipSevenEleven">7-ELEVEN店到店（限重５公斤） $60</option>
                    <option value="shipFamilyMart">全家店到店（限重５公斤） $60</option>
                    <option value="shipHiLife">萊爾富店到店（限重５公斤） $60</option>
                    <option value="shipOK">OK店到店（限重５公斤） $60</option>
                    <option value="homeDelivery">宅配 $150</option>
                  </select>
                </div>
                <div v-if="current_order.ship_store != 'homeDelivery'" class="col">
                  <p>
                    <a :href="store_url[current_order.ship_store]" target="_blank" class="text-decoration-none">{{ store_urlName[current_order.ship_store] }}</a>
                  </p>
                  <input type="text" class="form-control" placeholder="門市店號" aria-label="store number" v-model="current_order.store_number" />
                </div>
                <div v-else class="col">
                  <p class="text-white">來點廣告詞</p>
                  <input type="text" class="form-control" placeholder="宅配地址" aria-label="store address" v-model="current_order.homeDelivery_address" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" placeholder="給浪勁烘⾖⼯作室的備註" aria-label="memo for wavepo roaster stutio" v-model="current_order.order_memo" />
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- {{ current_order }} -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">繼續選購</button>
          <button type="submit" class="btn btn-primary" @click="getSum">結帳</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useCartStore } from "@/store";
import { getProduct } from "@/global";
import { ref } from "@vue/reactivity";
const storeCart = useCartStore();
const current_order = ref({
  order_id: "",
  user_id: "",
  receiver_name: "",
  receiver_phone: "",
  ship_store: "shipSevenEleven",
  store_number: "",
  homeDelivery_address: "",
  order_memo: "",
  purchase_item: [],
});

const store_url = ref({
  shipSevenEleven: "https://emap.pcsc.com.tw/",
  shipFamilyMart: "https://www.famiport.com.tw/Web_Famiport/page/ShopQuery.aspx",
  shipHiLife: "https://www.hilife.com.tw/storeInquiry_shopNo.aspx",
  shipOK: "https://www.okmart.com.tw/convenient_shopSearch",
});
const store_urlName = ref({
  shipSevenEleven: "7-11店到店店號查詢",
  shipFamilyMart: "全家店到店店號查詢",
  shipHiLife: "萊爾富店到店店號查詢",
  shipOK: "OK店到店店號查詢",
});
</script>
